import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { showRouterFallback } from './fallback/Default';

import './App.css';

const Homepage = React.lazy(() => import('./page/Homepage'));
const Authentication = React.lazy(() => import('./page/Authentication'));
const Dashboard = React.lazy(() => import('./page/Dashboard'));
const Scan = React.lazy(() => import('./page/Scan'));
const Verification = React.lazy(() => import('./page/Verification'));

function App() {
  return (
    <Router>
      <Suspense fallback={showRouterFallback()}>
        <Switch>
          <Route exact path='/'><Homepage/></Route>
          <Route path='/claim_promo'><Authentication/></Route>
          <Route path='/dashboard'><Dashboard/></Route>
          <Route path='/scan'><Scan/></Route>
          <Route path='/verify'><Verification/></Route>
          <Route><Redirect to='/'/></Route>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
